import React from 'react';
import ReactDOM from 'react-dom/client';

class App extends React.Component {
    render() {
        return(
            <div>hello randall</div>
        )
    }
}

export default App;